import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const SignIn = () => {
  const [cnp, setCnp] = useState("");
  const [password, setPass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const axiosInsance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SITE_BACKEND,
  });
  const { pass } = useParams();

  useEffect(() => {
    getSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSession = async () => {
    try {
      await axiosInsance.get(`/`).then((rez) => {
        navigate("/user/mainPage");
      });
    } catch (error) {
      console.clear();
      if (pass) {
        setPass(pass);
      }
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const token = captchaRef.current.getValue();
      await axiosInsance.post(`/auth/login`, {
        cnp,
        password,
        captchaToken: token,
      });
      navigate("/user/mainPage");
    } catch (error) {
      setErrorMessage(error.response.data.message);
      console.clear();
    }
  };
  // return (
  //     <div className="Auth-form-container">
  //         <form className="Auth-form" onSubmit={signIn}>
  //             <div className="Auth-form-content">
  //                 <h3 className="Auth-form-title"> Sign In</h3>
  //                 <div className="mb-3">
  //                     <label>{errorMessage}</label>
  //                 </div>

  //                 <div className="form-group mt-3">
  //                     <label className="label"> CNP </label>
  //                     <input
  //                         type="text"
  //                         className="form-control mt-1"
  //                         value={cnp}
  //                         onChange={(e) => setCnp(e.target.value)}
  //                         placeholder="CNP"
  //                     />
  //                 </div>
  //                 <div className="form-group mt-3">
  //                     <label className="label">Password</label>
  //                     <input
  //                         type="password"
  //                         className="form-control"
  //                         value={password}
  //                         onChange={(e) => setPass(e.target.value)}
  //                         placeholder="Parola"
  //                     />
  //                 </div>

  //                 <div className='d-grid gap-2 mt-3'>
  //                     <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} hl={'ro'} ref={captchaRef} />
  //                 </div>
  //                 <div className="d-grid gap-2 mt-3">
  //                     <button type="submit" className="btn btn-primary">
  //                         Log In
  //                     </button>
  //                 </div>
  //             </div>
  //         </form>
  //     </div>
  // );

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div id="logInBodyStyle" className="login-page">
      <div
        style={{
          alignContent: "center",
          backgroundColor: "rgba(52, 52, 52, 0.5)",
          borderRadius: 10,
          margin: 10,
          marginBottom: 50,
        }}
      >
        <h1
          id="headerTitle"
          style={{ color: "red", fontSize: "3rem", margin: 5 }}
        >
          Atentie!
        </h1>
        <h2
          id="headerTitle"
          style={{ color: "White", fontSize: "2rem", margin: 7 }}
        >
          Accesarea rezultatului este gratuită!
        </h2>
        <h2
          id="headerTitle"
          style={{ color: "White", fontSize: "2rem", margin: 7 }}
        >
          Dacă nu vă puteți loga, vă rugăm să trimiteți un e-mail la adresa

          <a href="mailto:sccut@insmc.ro"> sccut@insmc.ro </a>

           cu numele complet si CNP-ul și vă vom răspunde în cel
          mai scurt timp.
        </h2>
      </div>
        <div className="login-box">
          <div
            className="card card-outline card-primary"
            data-card-widget="card-refresh"
          >
            <h2 id="headerTitle">Log In</h2>
            <div>
              <div id="text" className="row">
                <text>{errorMessage}</text>
              </div>
              <div className="row">
                <label></label>
                <input
                  type="text"
                  placeholder="CNP"
                  maxLength={15}
                  value={cnp}
                  onChange={(e) => setCnp(e.target.value)}
                />
              </div>

              <div className="row">
                <label></label>
                <input
                  type={passwordType}
                  placeholder="Parola"
                  value={password}
                  maxLength={15}
                  onChange={(e) => setPass(e.target.value)}
                />
                <button
                  className="btn btn-outline-primary"
                  onClick={togglePassword}
                  style={{
                    position: "absolute",
                    background: "none",
                    width: "10%",
                    left: "80%",
                    top: "52%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {passwordType === "password" ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      style={{ color: "gray" }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faEye} style={{ color: "gray" }} />
                  )}
                </button>
              </div>
            </div>
            <div id="capcha" className="row">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                hl={"ro"}
                ref={captchaRef}
              />
            </div>
            <div id="button" className="row">
              <button onClick={signIn}>Log In</button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default SignIn;
