import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import date from "date-and-time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

const UserMainPage = () => {
  const [results, setResults] = useState([]);
  const [user, setUser] = useState({});
  const navigate = useNavigate();


  


  const axiosInsance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SITE_BACKEND,
  });
  useEffect(() => {
    // if (window.performance) {
    //   if (performance.navigation.type == 1) {
    //     //navigate("/signin");
    //   } else {
    //     alert( "This page is not reloaded");
    //   }
    // }
    getSession();
    getResList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSession = async () => {
    try {
      const rez = await axiosInsance.get(`/`);
      setUser(rez.data);
    } catch (error) {
      if(error.response.status === 401)
      navigate("/signin");
      else
      console.clear();
    }
  };
  Component.componentDidMount = () => {
    const script = document.createElement("script");

    script.src = 'js/content.js';
    script.async = true;

    document.body.appendChild(script);
  }

  const downloadFile = async (result, sampleId) => {
    try {
      await axiosInsance
        .get(`/user/downloadFile/${sampleId}`, { responseType: "blob" })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const filePdf = URL.createObjectURL(
            file,
            `${result.samplenumber}.pdf`
          );
          window.open().location = filePdf;
        });
      await axiosInsance.post(`/user/setdownload`, {
        downloadedfileId: sampleId,
        downlodedfile: result.samplenumber,
        usercnp: result.userCnp,
      });
    } catch (error) {
      if(error.response.status === 401)
      navigate("/signin");
      else
      console.clear();
    }
  };

  const getLetter = async (result) => {
    try {
      await axiosInsance
        .get(`/user/getLetter/${result.samplenumber}`, { responseType: "blob" })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const filePdf = URL.createObjectURL(file, `Scrisoare.pdf`);
          window.open().location = filePdf;
        });
    } catch (error) {
      if(error.response.status === 401)
      navigate("/signin");
      else
      console.clear();
    }
  };

  const getResList = async (e) => {
    try {
      const response = await axiosInsance.get(
        `${process.env.REACT_APP_SITE_BACKEND}/user/getResults`
      );
      //console.log(response);
      setResults(response.data);
    } catch (error) {
      if(error.response.status === 401)
      navigate("/signin");
      else
      console.clear();
      //navigate("/signin");
    }
  };

  const logout = async () => {
    try {
      await axiosInsance
        .get(`${process.env.REACT_APP_SITE_BACKEND}/auth/logout`)
        .then((res) => {
          navigate("/signin");
        });
    } catch (error) {
      if(error.response.status === 401)
      navigate("/signin");
      else
      console.clear();
    }
  };

  return (
    <div
      id="bodyStyle"
      className="layout-boxed sidebar-collapse sidebar-closed"
    >
      <div className="wrapper">
    
        <section className="content-header">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <div>
              <h1 style={{ fontWeight: "bold", color: "white" }}>
                {user.firstName}
              </h1>
              <h1 style={{ fontSize: 40, fontWeight: "bold", color: "white" }}>
                {user.lastName}
              </h1>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <button className="btn btn-block btn-danger" onClick={logout}>
                Log Out
              </button>
            </div>
          </div>
        </section>


        <section className="content">
          <div className="container-fluid">
            <div className="card card-info" >
              <div className="card-header">
                <h3 className="card-title">Rezultate analize</h3>
                <div className="card-tools">
                  {/* Buttons, labels, and many other things can be placed here! 
                            Here is a label for example  */}
                  <span className="badge badge-primary"></span>
                </div>
              </div>

              <div className="card-body" >
                {/* table-responsive p-0"> */}
                <div
                  id="example2_wrapper"
                  className="table"
                >
                  {/* <div className="row"> */}
                    {/* <div className="col-sm-12"> */}
                      <Table >
                        <Thead>
                          <Tr>
                            <Th >Serie Formular</Th>
                            {/* <th>Test</th> */}
                            <Th  >Data prelevării</Th>
                            <Th>CNP</Th>
                            <Th>Buletin HPV</Th>
                            <Th>Buletin BP</Th>
                            <Th>Scrisoare</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results
                            .sort((a, b) => (a.date < b.date ? 1 : -1))
                            .map((result) => (
                              <Tr  key={result.samplenumber}>
                                <Td >{result.samplenumber}</Td>
                                {/* <td>{result.institute}</td> */}
                                <Td>
                                  {date.format(
                                    new Date(result.date),
                                    "DD/MM/YYYY"
                                  )}
                                </Td>
                                <Td>{result.userCnp}</Td>
                                <Td>
                                  {result.buletinHpv ? (
                                    <button
                                      type="button"
                                      className="btn btn-block bg-gradient-primary "
                                      style={{ width: 100 }}
                                      onClick={() =>
                                        downloadFile(result, result.buletinHpv)
                                      }
                                    >
                                      Deschideti
                                      {/* <FontAwesomeIcon icon={faEye} /> */}
                                    </button>
                                  ) : result.bpRez === "NILM" ? (
                                    "Nu este necesar conform protocolului."
                                  ) : (
                                    "Va rugam asteptati rezultatul testului HPV"
                                  )}
                                </Td>
                                <Td>
                                  {result.buletinBp ? (
                                    <button
                                      type="button"
                                      className="btn btn-block bg-gradient-primary "
                                      style={{ width: 100 }}
                                      onClick={() =>
                                        downloadFile(result, result.buletinBp)
                                      }
                                    >
                                      Deschideti
                                      {/* <FontAwesomeIcon icon={faEye} /> */}
                                    </button>
                                  ) : result.hpvRez === "NEGATIV" ? (
                                    "Nu este necesar conform protocolului."
                                  ) : (
                                    "Va rugam asteptati rezultatul testului BP"
                                  )}
                                </Td>
                                <Td>
                                  {result.hpvRez === "NEGATIV" ||
                                  result.bpRez === "NILM" ? (
                                    <button
                                      type="button"
                                      className="btn btn-block bg-gradient-primary"
                                      style={{ width: 100 }}
                                      onClick={() => getLetter(result)}
                                    >
                                      Deschideti
                                      {/* <FontAwesomeIcon icon={faEye} /> */}
                                    </button>
                                  ) : (result.hpvRez !== "NEGATIV" &&
                                      result.bpRez === null) ^
                                    (result.hpvRez === null &&
                                      result.bpRez !== "NILM") ? (
                                    "Disponibil dupa finalizarea ambelor teste."
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-block bg-gradient-primary"
                                      style={{ width: 100 }}
                                      onClick={() => getLetter(result)}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </button>
                                  )}
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    {/* </div> */}
                  {/* </div> */}
                </div>
                </div>
              <div className="card-footer">
                Atentie! Odata cu rezultatul, va rugam sa cititi si scrisoarea
                de recomandare!
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
    
  );
};

export default UserMainPage;

//CSS
// const styles = {
//     flexboxContainer: {
//         display: 'flex',
//         flexdirection: 'row',
//         justifyContent: 'center',
//         alignItems: 'center'
//     },

//     logoutButton: {
//         marginLeft: "auto",
//         type: "button",

//     }
// };
