import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bulma/css/bulma.css";

const container  = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <App />
);
reportWebVitals();
