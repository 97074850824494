import React from 'react';
import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from './components/SignIn';
import UserMainPage from './components/UserMainPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path='/signin/:pass' element={<SignIn />} />
        <Route path="/user/mainPage" element={<UserMainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
